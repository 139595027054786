<template>
  <div :class="outerClass">
    <span
      v-if="playAudioStatus"
      class="anticon-yyashow anticon-zanting"
      :style="{ color: themeColor }"
      @click="stopAudio"
    ></span>
    <span v-else @click="playAudio" class="anticon-yyashow anticon-bofang"></span>
  </div>
</template>

<script>
import globalConfig from '../../store/global_config';
import { showMyToast } from '../../utils/common';
export default {
  data() {
    return {};
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    playAudioStatus() {
      return globalConfig.state.playAudioStatus;
    },
    // 录音状态
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    },
    // 音频是否自动播放
    audioAutoPlay() {
      return globalConfig.state.audioAutoPlay;
    }
  },

  watch: {
    audioAutoPlay(newValue) {
      if (newValue) {
        this.playAudio();
      }
    }
  },

  props: {
    src: {
      type: String,
      default: ''
    },
    outerClass: {
      type: String,
      default: ''
    }
  },

  methods: {
    // 开始播放
    playAudio() {
      if (this.recorderStatus) {
        showMyToast('w', '正在录音');
        return;
      }
      globalConfig.commit('stopAudio');
      globalConfig.commit('playAudio', {
        src: this.src
      });
    },

    // 暂停播放
    stopAudio() {
      globalConfig.commit('stopAudio');
    }
  }
};
</script>

<style lang="less" scoped>
.anticon-yyashow {
  font-size: 50px;
}
</style>
