<template>
  <video
    :ref="'video_' + hisIndex"
    @timeupdate="videoTimeUpdate"
    :src="src"
    @ended="videoEnded"
    controls
    autoplay
  ></video>
</template>

<script>
import globalConfig from '@/store/global_config';
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    hisIndex: {
      type: [Number, String],
      default: ''
    }
  },

  methods: {
    videoTimeUpdate() {
      if (this.$refs['video_' + this.hisIndex]) {
        const { duration, currentTime } = this.$refs['video_' + this.hisIndex];
        globalConfig.commit('changeVideoProgress', currentTime / duration);
      }
    },

    videoEnded() {
      globalConfig.commit('changeVideoProgress', 1);
      this.$emit('videoEnd');
    }
  }
};
</script>

<style lang="less" scoped>
video {
  width: 100%;
}
</style>
