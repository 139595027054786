import { render, staticRenderFns } from "./Preparation.vue?vue&type=template&id=4e040df4&scoped=true&"
import script from "./Preparation.vue?vue&type=script&lang=js&"
export * from "./Preparation.vue?vue&type=script&lang=js&"
import style0 from "./Preparation.vue?vue&type=style&index=0&id=4e040df4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e040df4",
  null
  
)

export default component.exports