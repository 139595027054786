<template>
  <div class="report_container">
    <div class="evaluate_container flex_column">
      <div class="title_static flex_column">
        <div class="title_box">
          <div class="flex_title">
            <div class="line" :style="{ backgroundColor: themeColor }"></div>
            <span class="text">评测报告</span>
            <div class="line" :style="{ backgroundColor: themeColor }"></div>
          </div>
        </div>
        <div class="main_box">
          <div id="main" style="width: 400px; height:300px;"></div>
        </div>
      </div>
      <div @click="hideReportBox" class="anticon-shanchu anticon-yyashow delete_icon"></div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';
import globalConfig from '@/store/global_config';
export default {
  data() {
    return {
      tempCanvasImg: ''
    };
  },

  props: {
    reportItem: {
      default: () => {},
      type: Object
    }
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  mounted() {
    const { used } = this.reportItem;
    if (used) {
      const { overall, pron, integrity, fluency } = used.read_result.result;
      this.columnShow({ overall, pron, integrity, fluency });
    }
  },

  methods: {
    columnShow(speakScoreArr) {
      const { overall, pron, integrity, fluency } = speakScoreArr;
      const overallScore = overall || 0;
      const pronScore = pron || 0;
      const integrityScore = integrity || 0;
      const fluencyScore = fluency || 0;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '评测得分统计'
        },
        tooltip: {},
        radar: {
          indicator: [
            {
              name: `总分(${overall})`,
              max: 100
            },
            {
              name: `发音(${pron})`,
              max: 100
            },
            {
              name: `完整度(${integrity})`,
              max: 100
            },
            {
              name: `流利度(${fluency})`,
              max: 100
            }
          ]
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: [overallScore, pronScore, integrityScore, fluencyScore],
                name: '我的得分'
              }
            ]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    hideReportBox() {
      this.$emit('hideReportBox');
    }
  }
};
</script>

<style lang="less" scoped>
#main {
  margin: 0 auto;
}
.report_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .delete_icon {
    font-size: 35px;
    color: white;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
  .evaluate_container {
    width: 70%;
    margin: 0 auto;
    height: 80%;
    top: 10%;
    .title_static {
      padding: 20px;
      background: white;
      border-radius: 20px;
      flex: 2;
      overflow-y: scroll;
      height: 100%;
      .title_box {
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-around;
        .flex_title {
          display: flex;
          align-items: center;
          width: 100%;
          .line {
            width: 30%;
            height: 2px;
          }
          .text {
            text-align: center;
            width: 40%;
          }
        }
      }
      .main_box {
        flex: 2;
      }
      .empty_box {
        height: 600px;
      }
      .canvas {
        width: 600px;
        height: 600px;
        margin: 0 auto;
      }
    }
  }
}
</style>
