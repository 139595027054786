<template>
  <div class="my_footer">
    <div class="prev_ques">
      <div
        v-if="outerIndex !== 0 && !hidePrevIcon"
        @click="prevBtn(hisIndex)"
        :style="{ backgroundColor: themeColor }"
        class="prev_text"
      >
        <span
          class="anticon-yyashow anticon-shangyiti sideIcon color_white"
        ></span>
        <span class="side_text">{{ ' ' }}prev</span>
      </div>
    </div>
    <div
      v-if="showResetImg"
      class="reset"
      :style="{ color: 'white', backgroundColor: themeColor }"
      @click="resetCurrentItem"
    >
      重做
    </div>
    <div class="next_ques">
      <!-- v-if="hasConfirmClick && hisIndex + 1 !== allLength" -->
      <div
        :style="{ backgroundColor: themeColor }"
        class="next_text"
        @click="toNextChoose"
      >
        <span class="side_text">next{{ ' ' }}</span>
        <span
          class="anticon-yyashow anticon-xiayiti sideIcon color_white"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import globalConfig from '../../store/global_config';
export default {
  props: {
    outerIndex: {
      type: [Number, String],
      default: 0
    },
    showResetImg: {
      type: Boolean,
      showResetImg: false
    },
    hasConfirmClick: {
      type: Boolean,
      showResetImg: false
    },
    hisIndex: {
      type: [Number, String],
      default: 0
    },
    allLength: {
      type: [Number, String],
      default: 0
    },
    // 是否显示返回上一页
    hidePrevIcon: {
      type: Boolean,
      showResetImg: false
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  methods: {
    toNextChoose() {
      this.$emit('toNext');
    },
    prevBtn() {
      this.$emit('toPrev');
    },
    resetCurrentItem() {
      this.$emit('resetItem');
    }
  }
};
</script>

<style lang="less" scoped>
.my_footer {
  height: 70px;
  line-height: 70rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .reset {
    width: 50px;
    height: 50px;
    background-color: lightgray;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
  }
  .prev_ques {
    width: 60px;
    height: 30px;
    text-align: center;

    .prev_text {
      line-height: 30px;
      font-size: 0.77rem;
      width: 60px;
      height: 30px;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      color: white;
    }
  }
  .next_ques {
    width: 60px;
    height: 30px;
    text-align: center;
    .next_text {
      line-height: 30px;
      font-size: 0.77em;
      width: 60px;
      height: 30px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      color: white;
    }
  }
  .side_text {
    vertical-align: top;
  }
}
</style>
