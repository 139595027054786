<template>
  <div v-if="scoreObj.en_name" class="score_container" :style="{ backgroundColor: themeColor }">
    <star :star="hisScore"></star>
    <div class="his_score" @click="toShowReport">
      <template v-if="evalResultShow == 2">
        <span class="en_score">{{ hisScore }}</span>
        <span class="score_text">分</span>
      </template>
      <span class="en_score" v-else>{{ scoreObj.en_name }}</span>
      <div class="triangle_container" v-if="showReport" @click.stop="toShowReport">
        <span class="text_box" :style="{ backgroundColor: themeColor }">评测报告</span>
        <div class="triangle_box" :style="{ backgroundColor: themeColor }"></div>
      </div>
    </div>
    <span
      v-if="showTranslateIcon"
      :style="{
        color: !outerTranslateShow ? 'rgba(255,255,255,0.75)' : 'white'
      }"
      class="anticon-translate anticon-yyashow score_translate_img"
      @click="toggleShowTranslate"
    ></span>
    <div @click="playHisRecord" class="play_audio_box" v-if="!playRecordStatus">
      <img
        v-if="imgUrl"
        key="img"
        class="listen_img"
        mode="widthFix"
        :src="imgUrl + 'book/play/listen_self.png'"
      />
    </div>
    <div v-else class="play_audio_box" @click="stopPlayHisRecord">
      <img
        v-if="imgUrl"
        key="img"
        class="listen_img"
        mode="widthFix"
        :src="imgUrl + 'book/play/listen_self.png'"
      />
      <water key="water"></water>
    </div>
  </div>
</template>

<script>
import Star from '@/components/Star';
import Water from '@/components/Water';
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, getLightColor } from '@/utils/common';
import { getScoreObjByScore } from '../../utils/common';
const { imgUrl } = PUBLIC_DATA;

export default {
  props: {
    // 评测对象
    itemUsedData: {
      type: Object,
      default: () => {}
    },
    // 父类此时翻译的显示状态
    outerTranslateShow: {
      default: false,
      type: Boolean
    },
    // 是否自动播放录音按钮
    canPlayRecord: {
      default: false,
      type: Boolean
    },
    // 是否显示翻译icon
    showTranslateIcon: {
      default: false,
      type: Boolean
    },
    // 是否显示评测报告
    showReport: {
      default: false,
      type: Boolean
    }
  },

  components: {
    Star,
    Water
  },

  data() {
    return {
      // 是否是播放录音状态
      imgUrl: imgUrl,
      isPlayingRecord: false
    };
  },

  watch: {
    recordAutoPlay(newValue) {
      if (newValue) {
        this.playHisRecord();
      }
    }
  },

  computed: {
    // 播放录音状态
    playRecordStatus() {
      return globalConfig.state.playRecordStatus;
    },
    themeColor() {
      return globalConfig.state.themeColor;
    },
    scoreArr() {
      return globalConfig.state.scoreArr;
    },
    // 是1就显示英语，2就显示分数
    evalResultShow() {
      return globalConfig.state.evalResultShow;
    },
    ligthThemeColor() {
      return getLightColor(globalConfig.state.themeColor, 0.3);
    },
    scoreObj() {
      if (this.hisScore === 'null') {
        return {};
      }
      return getScoreObjByScore(this.hisScore, this.scoreArr) || {};
    },
    userRecordurl() {
      const { read_result: readResult, read_audio_url: readAudioUrl } = this.itemUsedData;
      return readAudioUrl || readResult.audioUrl;
    },
    hisScore() {
      const { read_result: readResult = {} } = this.itemUsedData || {};
      return readResult.result ? readResult.result.overall : 'null';
    },
    recordAutoPlay() {
      return globalConfig.state.recordAutoPlay;
    }
  },

  methods: {
    toggleShowTranslate() {
      this.$emit('translateClick');
    },

    stopPlayHisRecord() {
      globalConfig.commit('stopAudio');
    },

    playHisRecord() {
      if (!this.userRecordurl) {
        return;
      }
      globalConfig.commit('changeRecordAutoPlay', false);
      globalConfig.commit('playAudio', {
        src: this.userRecordurl,
        type: 'record'
      });
    },

    toShowReport() {
      this.$emit('showReportBox');
    }
  }
};
</script>

<style lang="less" scoped>
.score_container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 9;
  .play_audio_box {
    position: relative;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .listen_img {
      width: 30px;
      height: 30px;
    }
  }
  .his_score {
    color: #ffffff;
    font-size: 0;
    position: relative;
    width: 230px;
    text-align: center;
    .triangle_container {
      left: 0;
      color: white;
      position: absolute;
      width: 100%;
      top: -60px;
      height: 33px;
      position: absolute;
      white-space: nowrap;
      padding-bottom: 20px;
      z-index: 10;
      .text_box {
        padding: 10px 15px;
        font-size: 18px;
        border-radius: 10px;
      }
      .triangle_box {
        z-index: -10;
        width: 20px;
        height: 20px;
        transform: translateX(-50%) rotate(45deg);
        left: 50%;
        border-top-color: transparent;
        border-left-color: transparent;
        position: absolute;
        bottom: -10px;
      }
    }
    .en_score {
      font-size: 30px;
      line-height: 30px;
    }
    .score_text {
      color: #ffffff;
      font-size: 30px;
    }
  }
  .anticon-yyashow.score_translate_img {
    font-size: 30px;
    line-height: 30px;
  }
}
</style>
