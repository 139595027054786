<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">{{ gameName }}</div>
    <div class="game_number text_center margin_top_20">
      {{ curItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <div class="report_box" v-if="reportBoxStatus">
        <evaluate-report
          :reportItem="curItem"
          @hideReportBox="hideReportBox"
        ></evaluate-report>
      </div>
      <!-- 内容框 -->
      <div
        class="content_container"
        :style="{
          backgroundImage: outerBgImg(curItem.hisImg),
          paddingBottom: curItem.used ? '50px' : '0'
        }"
      >
        <div
          class="img_outer text_center"
          v-if="curItem.hisImg && curItem.imgMode === 1"
        >
          <img :src="imgUrl + curItem.hisImg" class="inner_img" alt />
        </div>

        <template v-if="curItem.hisRrcordTextColor">
          <pre>
            <span
              :style="{ color: item.itemColor }"
              v-for="item in curItem.hisRrcordTextColor"
              :key="item.itemKey"
            >
              {{ item.itemText }}{{ ' ' }}
            </span>
          </pre>
        </template>
        <template v-else>
          <template v-if="curItem.syncText.length > 0">
            <pre>
              <span :style="{...wordColor(item)}" v-for="item in curItem.syncText" :key="item.start">{{item.word}}{{' '}}</span>
            </pre>
          </template>
          <pre v-else>{{ curItem.text.en_content }}</pre>
        </template>

        <pre v-if="statusShowTranslate">{{ curItem.text.cn_content }}</pre>
      </div>
      <!-- 其他元素 -->
      <span
        :style="{ color: statusShowTranslate ? themeColor : 'inhert' }"
        v-if="curItem.text.cn_content"
        class="anticon-translate anticon-yyashow translate_img"
        @click="toggleShowTranslate(outerItem)"
      ></span>
      <div class="score_container_bottom" v-show="curItem.used">
        <score-container
          showReport
          @showReportBox="showReportBox"
          :itemUsedData="curItem.used"
        ></score-container>
      </div>
    </div>
    <footer-record
      :hisIndex="curItem.hisPageIndex"
      @toNext="toNext"
      @toPrev="toPrev"
      :src="curItem.text.audio_url"
      :evalTime="hisRecordTime"
      @startRecorder="startRecorder"
      @stopCountDown="stopCountDown"
    ></footer-record>
  </div>
</template>

<script>
import FooterRecord from '@/components/FooterRecord';
import globalConfig from '@/store/global_config';
import ScoreContainer from '@/components/ScoreContainer';
import EvaluateReport from '../../../components/EvaluateReport';
import { showMySuccess, PUBLIC_DATA, showMyToast } from '@/utils/common';
import { saveUserGameTask } from '@/api/service';
import {
  showLoading,
  hideLoading,
  getStartRecordObj,
  getColorTextArr
} from '../../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { FooterRecord, ScoreContainer, EvaluateReport },

  data() {
    return {
      imgUrl: imgUrl,
      allLength: 0,
      currentIndex: 0,
      allListData: [],
      alreadyUsedData: [],
      statusShowTranslate: false,
      reportBoxStatus: false
    };
  },
  props: {
    gameName: {
      type: String,
      default: ''
    },

    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    chapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },

  beforeDestroy() {
    globalConfig.commit('resetRecorder');
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    curItem() {
      const defaultValue = {
        text: {},
        hisIndex: 0,
        syncText: [],
        used: {}
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    hisRecordTime() {
      const {
        text: { en_content: enContent = '' }
      } = this.curItem;
      const textArr = enContent.split(' ');
      return 2 + textArr.length * 1.2;
    },

    minScoreNumber() {
      return (
        globalConfig.state.scoreArr[2] && globalConfig.state.scoreArr[2].min
      );
    },
    audioPercent() {
      return globalConfig.state.audioPercent;
    },
    playAudioStatus() {
      return globalConfig.state.playAudioStatus;
    },
    audioCurTime() {
      return globalConfig.state.audioCurTime * 1000;
    },
    recorderPlayEnd() {
      return globalConfig.state.recorderPlayEnd;
    }
  },

  watch: {
    recorderPlayEnd(newValue) {
      if (newValue && this.needJump) {
        this.needToJump();
      }
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.allLength = this.itemList.length;
    globalConfig.commit('changeAudioAutoPlay', true);
  },

  methods: {
    needToJump() {
      this.toNextSetTimeOut = setTimeout(() => {
        this.toNext();
      }, 2000);
    },

    showReportBox() {
      clearTimeout(this.toNextSetTimeOut);
      this.reportBoxStatus = true;
    },

    hideReportBox() {
      this.needToJump();
      this.reportBoxStatus = false;
    },

    wordColor(item) {
      const { start, end } = item;
      if (
        this.audioCurTime > 0 &&
        this.audioCurTime > start &&
        this.audioCurTime <= end
      ) {
        return {
          backgroundColor: this.themeColor,
          color: 'white'
        };
      }
      return {
        backgroundColor: 'inherit',
        color: 'inherit'
      };
    },

    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    outerBgImg(itemImg) {
      if (!itemImg) {
        return '';
      }
      const { imgMode } = this.curItem;
      if (imgMode === 2) {
        return 'url(' + imgUrl + itemImg + ')';
      }
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        const { text, used } = item;
        const { img_mode: imgMode, img_url: hisImg } = text;
        if (!used) {
          newArr.push({
            ...item,
            imgMode: imgMode,
            hisImg: hisImg,
            hisIndex: index,
            syncText: text.sync_text || [],
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    toggleShowTranslate() {
      this.statusShowTranslate = !this.statusShowTranslate;
    },

    saveUserRecorder(data) {
      showLoading();

      saveUserGameTask({
        gameId: this.gameId,
        typeId: this.typeId,
        book_chapter_id: this.chapterId,
        word_choice_id: this.curItem.id,
        user_answer: '',
        read_result: data
      }).then(() => {
        hideLoading();
        this.allListData = this.formatRecordData(data);
        globalConfig.commit('changeRecordAutoPlay', true);
      });
    },

    formatRecordData(data) {
      const newArr = [];
      const { id } = this.curItem;
      const hisRrcordTextColor = getColorTextArr(data);
      const {
        result: { overall }
      } = data;
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(item.id) === Number(id)) {
          newArr[index].hisRrcordTextColor = hisRrcordTextColor;
          newArr[index].used = {
            read_result: data
          };
          newArr[index].canJump = overall >= this.minScoreNumber;
        }
      });
      return newArr;
    },

    stopCountDown() {
      globalConfig.commit('stopRecorder');
    },

    resetItemUsed() {
      const newArr = [];
      this.allListData.forEach((item, index) => {
        newArr.push(item);
        if (Number(item.id) === Number(this.curItem.id)) {
          newArr[index].used = {};
          newArr[index].hisRrcordTextColor = '';
        }
      });
      this.allListData = newArr;
    },

    startRecorder() {
      clearTimeout(this.toNextSetTimeOut);
      if (this.curItem.used) {
        this.resetItemUsed();
      }
      globalConfig.commit('startRecorder', {
        recordParams: getStartRecordObj(
          'pred',
          this.hisRecordTime * 1000,
          this.curItem.text.en_content
        ),
        startCallback: () => {},
        getEvalMessage: this.saveUserRecorder
      });
    },

    toNext() {
      clearTimeout(this.toNextSetTimeOut);
      const { used } = this.curItem;
      if (!used) {
        showMyToast('w', '先完成跟读');
        return;
      }
      if (this.curItem.hisPageIndex + 1 >= this.allListData.length) {
        this.hasFinished();
        return;
      }
      setTimeout(() => {
        this.currentIndex += 1;
        globalConfig.commit('changeAudioAutoPlay', true);
      }, 500);
    },

    toPrev() {
      clearTimeout(this.toNextSetTimeOut);
      globalConfig.commit('stopAudio');
      this.currentIndex -= 1;
    }
  }
};
</script>

<style lang="less" scoped>
@import '../index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    position: relative;
    .content_container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding: 0 30px;
      .img_outer {
        .inner_img {
          height: 300px;
        }
      }
    }

    .translate_img {
      position: absolute;
      font-size: 30px;
      right: 40px;
      bottom: 40px;
    }
    .score_container_bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}

.report_box {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
</style>
