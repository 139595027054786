<template>
  <div class="my_footer">
    <div class="outer_pre_box">
      <div
        v-if="hisIndex != 0"
        @click="toPrev"
        class="color_white side_pre_box"
        :style="{ backgroundColor: themeColor }"
      >
        <span class="anticon-yyashow anticon-shangyiti color_white"></span>
        <span>{{ ' ' }}prev</span>
      </div>
    </div>

    <div class="flex_row">
      <audio-play :src="src"></audio-play>
      <div class="margin_left_20 flex_center" v-if="!hideRecorder">
        <count-down
          :countTime="evalTime"
          v-if="recorderStatus"
          @stopCountDown="stopCountDown"
        ></count-down>
        <span @click="toStartRecorder" v-else class="anticon-yyashow anticon-luyin2"></span>
      </div>
    </div>

    <div>
      <div
        @click="toNext"
        class="color_white side_nex_box"
        :style="{ backgroundColor: themeColor }"
      >
        <span>next{{ ' ' }}</span>
        <span class="anticon-yyashow anticon-xiayiti color_white"></span>
      </div>
    </div>
  </div>
</template>

<script>
// 支持跟读的footer
import globalConfig from '@/store/global_config';
import CountDown from '@/components/CountDown';
import AudioPlay from '@/components/AudioPlay';
export default {
  components: { CountDown, AudioPlay },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    // 录音状态
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    hisIndex: {
      type: [String, Number],
      default: ''
    },
    evalType: {
      type: String,
      default: ''
    },
    hideRecorder: {
      type: Boolean,
      default: false
    },
    evalTime: {
      type: [String, Number],
      default: 5
    }
  },
  data() {
    return {
      playAudioStauts: false
    };
  },

  mounted() {},

  methods: {
    toPrev() {
      this.$emit('toPrev');
    },

    toNext() {
      this.$emit('toNext');
    },

    // 去开启录音
    toStartRecorder() {
      this.$emit('startRecorder');
    },

    stopCountDown() {
      this.$emit('stopCountDown');
    }
  }
};
</script>

<style lang="less" scoped>
.my_footer {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .outer_pre_box {
    min-width: 66px;
  }
  .side_pre_box {
    padding: 5px 11px 5px 6px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .side_nex_box {
    padding: 5px 6px 5px 11px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
.anticon-luyin2 {
  font-size: 50px;
}
</style>
