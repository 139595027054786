<template>
  <div class="outer_content_page flex_column">
    <div class="game_name text_center margin_top_20">{{ gameName }}</div>
    <div class="game_number text_center margin_top_20">
      {{ curItem.hisIndex + 1 }}/{{ allLength }}
    </div>
    <div class="inner_content">
      <!-- 内容框 -->
      <div
        class="content_container"
        :style="{ backgroundImage: outerBgImg(curItem.hisImg) }"
      >
        <div
          class="img_outer text_center"
          v-if="curItem.hisImg && curItem.imgMode === 1"
        >
          <img :src="imgUrl + curItem.hisImg" class="inner_img" alt />
        </div>
        <template v-if="curItem.syncText.length > 0">
          <pre>
            <span :style="{...wordColor(item)}" v-for="item in curItem.syncText" :key="item.start">{{item.word}}{{' '}}</span>
          </pre>
        </template>
        <pre v-else>{{ curItem.text.en_content }}</pre>
        <pre v-if="statusShowTranslate">{{ curItem.text.cn_content }}</pre>
      </div>
      <!-- 其他元素 -->
      <span
        :style="{ color: statusShowTranslate ? themeColor : 'inhert' }"
        v-if="curItem.text.cn_content"
        class="anticon-translate anticon-yyashow translate_img"
        @click="toggleShowTranslate(outerItem)"
      ></span>
      <div
        class="score_container_bottom"
        style="position: relative"
        v-if="curItem.used"
      >
        <score-container :itemUsedData="curItem.used"></score-container>
      </div>
    </div>
    <footer-record
      :hisIndex="curItem.hisPageIndex"
      @toNext="toNext"
      @toPrev="toPrev"
      :src="curItem.text.audio_url"
      hideRecorder
    ></footer-record>
  </div>
</template>

<script>
import FooterRecord from '@/components/FooterRecord';
import globalConfig from '@/store/global_config';
import ScoreContainer from '@/components/ScoreContainer';
import { showMySuccess, PUBLIC_DATA, showMyToast } from '@/utils/common';
import { saveUserGameTask } from '@/api/service';
import { showLoading, hideLoading } from '../../../utils/common';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { FooterRecord, ScoreContainer },

  data() {
    return {
      imgUrl: imgUrl,
      allLength: 0,
      currentIndex: 0,
      allListData: [],
      alreadyUsedData: [],
      statusShowTranslate: false
    };
  },
  props: {
    gameName: {
      type: String,
      default: ''
    },

    itemList: {
      type: Array,
      default: () => []
    },
    bookId: {
      type: String,
      default: ''
    },
    chapterId: {
      type: String,
      default: ''
    },
    typeId: {
      type: String,
      default: ''
    },
    gameId: {
      type: String,
      default: ''
    }
  },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    curItem() {
      const defaultValue = {
        text: {},
        hisIndex: 0,
        syncText: []
      };
      return this.allListData[this.currentIndex] || defaultValue;
    },
    minScoreNumber() {
      return (
        globalConfig.state.scoreArr[2] && globalConfig.state.scoreArr[2].min
      );
    },
    audioPercent() {
      return globalConfig.state.audioPercent;
    },
    playAudioStatus() {
      return globalConfig.state.playAudioStatus;
    },
    audioCurTime() {
      return globalConfig.state.audioCurTime * 1000;
    }
  },

  watch: {
    audioPercent(newValue) {
      if (parseFloat(newValue) === 100) {
        this.toNext(100, 'no');
      }
    }
  },

  mounted() {
    this.allListData = this.formatUsedData(this.itemList);
    if (this.alreadyUsedData.length === this.itemList.length) {
      this.hasFinished();
      return;
    }
    this.allLength = this.itemList.length;
  },

  methods: {
    wordColor(item) {
      const { start, end } = item;
      if (
        this.audioCurTime > 0 &&
        this.audioCurTime > start &&
        this.audioCurTime <= end
      ) {
        return {
          backgroundColor: this.themeColor,
          color: 'white'
        };
      }
      return {
        backgroundColor: 'inherit',
        color: 'inherit'
      };
    },

    hasFinished() {
      showMySuccess('你已经学完', () => {
        window.history.back();
      });
    },

    outerBgImg(itemImg) {
      if (!itemImg) {
        return '';
      }
      const { imgMode } = this.curItem;
      if (imgMode === 2) {
        return 'url(' + imgUrl + itemImg + ')';
      }
    },

    formatUsedData(arr) {
      const newArr = [];
      let pageIndex = 0;
      arr.forEach((item, index) => {
        const { text, used } = item;
        const { img_mode: imgMode, img_url: hisImg } = text;
        if (!used) {
          newArr.push({
            ...item,
            imgMode: imgMode,
            hisImg: hisImg,
            hisIndex: index,
            syncText: text.sync_text || [],
            hisPageIndex: pageIndex++
          });
        } else {
          this.alreadyUsedData.push(item);
        }
      });
      return newArr;
    },

    toggleShowTranslate() {
      this.statusShowTranslate = !this.statusShowTranslate;
    },

    toNext(audioPercent, type) {
      if (this.playAudioStatus && type !== 'no') {
        showMyToast('w', '正在播放');
        return;
      }
      showLoading();
      saveUserGameTask({
        gameId: this.gameId,
        typeId: this.typeId,
        book_chapter_id: this.chapterId,
        word_choice_id: this.curItem.id,
        user_answer: '',
        read_result: audioPercent || parseFloat(this.audioPercent)
      }).then(() => {
        hideLoading();
        if (this.curItem.hisPageIndex + 1 >= this.allListData.length) {
          this.hasFinished();
          return;
        }
        setTimeout(() => {
          this.currentIndex += 1;
          globalConfig.commit('changeAudioAutoPlay', true);
        }, 500);
      });
    },

    toPrev() {
      globalConfig.commit('stopAudio');
      this.currentIndex -= 1;
    }
  }
};
</script>

<style lang="less" scoped>
@import '../index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    position: relative;
    .content_container {
      position: absolute;
      padding: 30px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      .img_outer {
        .inner_img {
          height: 300px;
        }
      }
    }

    .translate_img {
      position: absolute;
      font-size: 30px;
      right: 40px;
      bottom: 40px;
    }
    .score_container_bottom {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}
</style>
