<template>
  <div class="water_container">
    <div class="water1"></div>
    <div class="water2"></div>
    <div class="water3"></div>
    <div class="water4"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.water_container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .water1 {
    -webkit-animation: wateranimate 6s 6s ease-out infinite;
    animation: wateranimate 6s 6s ease-out infinite;
  }
  .water2 {
    -webkit-animation: wateranimate 6s 4s ease-out infinite;
    animation: wateranimate 6s 4s ease-out infinite;
  }
  .water3 {
    -webkit-animation: wateranimate 6s 2s ease-out infinite;
    animation: wateranimate 6s 2s ease-out infinite;
  }
  .water4 {
    -webkit-animation: wateranimate 6s 0s ease-out infinite;
    animation: wateranimate 6s 0s ease-out infinite;
  }
  .water1,
  .water2,
  .water3,
  .water4 {
    padding: 50%;
    position: absolute;
    left: 0%;
    top: 0%;
    border: 1px solid white;
    box-shadow: 0 0 30px 10px rgba(255, 255, 255, 1) inset;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
  }
  @-webkit-keyframes wateranimate {
    0% {
      -webkit-transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes wateranimate {
    0% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
}
</style>
